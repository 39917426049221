.choosePlan {
  background-color: white;
  padding: 70px 80px;
  // margin-bottom: 100px;
  .heading {
    color: #002857;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .subHeading {
    color: #999999;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .switchBox {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 60px;
    button {
      box-shadow: none;
    }
  }
  .formButtons {
    margin-top: 70px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 50px;
    button {
      padding: 10px;
      width: 200px;
      border-radius: 10px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .choosePlan {
    // margin-bottom: 50px;
  }
}

@media screen and (max-width: 930px) {
  .choosePlan {
    padding: 30px 30px;
    .switchBox {
      margin-bottom: 40px;
    }
  }
}
