.avatarStatus {
  width: 125px;
  height: 125px;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }

  .status {
    content: "";
    width: 30px;
    height: 30px;
    background-color: #C2C2C2;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    bottom: 3px;
    right: 3px;
    z-index: 2;
  }

  .status.active {
    background-color: #4BDD82;
  }

  .status.away {
    background-color: #e8ad23;
  }
  .status.offline {
    background-color: #C2C2C2;
  }

  @media (max-width: 600px) {
    width: 75px;
    height: 75px;

    .status {
      width: 16px;
      height: 16px;
      bottom: 1px;
      right: 1px;
    }
  }
}