.segmentBio {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  .bioUpperSection {
    padding: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: white;
  }
  .upperSectionMobile {
    background-color: #02afef;
    color: white;
    margin-bottom: 0px;
  }
  .avatarBio {
    margin-left: -10%;
  }
  [class~='ant-carousel'] {
    [class~='slick-slider'] {
      li button {
            width: 6px;
            height: 6px;
            border-radius: 100%;
            color: 002857 !important;
            background-color: white;
            border: 1px solid;
            border-color: 002857 !important;

          }
       li.slick-active button {
            width: 7px;
            height: 7px;
            border-radius: 100%;
            color: 002857 !important;
           // background-color: 002857 !important;
          }
          .slick-dots li button:before{
            background-color: #400505 !important;
     }
     .slick-dots li.slick-active button:before{
            background-color: #000 !important;
     }
    }
   
}
 
  .badge {
    border-radius: 4px;
    margin: 4px;
    font-size: 16px;
    font-weight: 500;
  }
  .segmentBioGreenBadge {
    background-color: rgba(125, 198, 153, 0.3);
    color: #7dc699;
  }
  .segmentBioBlueBadge {
    background-color: rgba(2, 175, 239, 0.3);
    color: #02afef;
  }
  .segmentBioYellowBadge {
    background-color: rgba(254, 164, 46, 0.25);
    color: #FEA42E;
  }
  .bioLowerSection {
    padding: 20px;
  }
  .bioLowerSectionMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding-top: 0px;
    margin-top: -10px;
  }
  .bioChipsMobile {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .skeletonAvatar {
    height: 70px;
    width: 70px;
  }
  .skeletonHeading {
    padding: 5px;
    width: 100px;
    border-radius: 15px;
  }
  .skeletonSubHeading {
    padding: 4px;
    width: 60px;
    border-radius: 15px;
  }
  .skeletonBadge {
    margin: 0px !important;
    padding: 16px;
    width: 90px;
    border-radius: 5px;
  }
  .skeletonText {
    width: 60%;
    border-radius: 10px;
  }
}

@media only screen and (max-width: 1360px) and (min-width: 1200px) {
  .segmentBio {
    .avatarBio {
      margin-left: 20px !important;
    }
  }
}
@media only screen and (min-width: 600px) {
  .customer-insights .ant-carousel .slick-track {
    overflow: scroll;
  }
}



.slick-track {
  margin-left: inherit;
  padding-bottom: 10px !important;
}
.ratingBox {
  display: inline-flex;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  width: fit-content;
  height: fit-content;
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 1px;
  padding-bottom: 1px;
  font-size: 3rem;
  padding: 0.01rem;
  p {
    margin-left: 6px;
    line-height: 18px;
    //font-size: 3rem;
  }
  svg {
    width: 30%;
    padding-left: 3px;
    padding-bottom: 2px;
  }
}

.viewReviews {
  color: #0050bc;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.listReviews {
  width: 73%;
  margin-left: 15%;
  margin-top: 10%;
  .reviewItem {
    padding: 10px 14px 17px 21px;
    box-sizing: border-box;
    border-radius: 5px;
    background: rgba(2, 175, 239, 0.05);
    margin-bottom: 29px;

    .itemHead {
      display: flex;
      align-items: end;
      margin-bottom: 17px;

      .ant-rate {
        font-size: 16px;
        margin: 0;
      }
      h5 {
        color: #707070;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0 0 0 17px;
      }
      p {
        color: #0050bc;
        text-align: right;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        margin: 0 0 0 auto;
      }
    }
    .comment {
      margin: 0 0 6px;
      color: #6f7174;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
    .readMore {
      color: #0050bc;
      font-size: 10px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
  }

  .viewMoreAction {
    text-align: right;
    margin-bottom: 60px;

    a {
      color: #0050bc;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}