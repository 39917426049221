.drawer {
  padding: 36px 38px 36px 58px;
  &-header {
    display: flex;
    justify-content: end;
    width: 100%;
    &-close {
      font-size: 24px;
      color: #002857;
      position: absolute;
      top: 26px;
      left: 23px;
      cursor: pointer;
      margin: 0;
    }
    &-state {
      display: flex;
      flex-direction: column;
      gap: 9px;
      color: #002857;
      font-size: 16px;
      text-align: right;
      font-weight: 500;
      align-items: flex-end;
      align-self: flex-end;
      &-status {
        display: flex;
        align-items: center;
        gap: 8px;
        margin: 0;
        color: #02afef;
        &-badge {
          &.online {
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #7dc699;
          }
          &.offline {
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #999;
          }
        }
      }
      &-checkDate {
        margin: 0;
        &-date {
          color: #002857;
          // color: #02afef;
          // text-transform: capitalize;
        }
      }
    }
  }
  &-content {
    display: flex;
    gap: 20px;
    margin-top: 75px;
    .avatar-mobile {
      display: none;
    }
    &-avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      width: 150px;
      height: 150px;
      font-weight: 700;
      font-size: 32px;
      text-transform: uppercase;
      background: url("../../../assets/img/avatar.svg") no-repeat;
    }
    &-detail {
      flex: 1;
      width: calc(100% - 170px);
      &-commonInfo {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 30px;
        margin-bottom: 48px;
        & > * {
          margin: 0;
        }
        font-family: "Lato-Bold";
        &-name {
          color: #002857;
          font-size: 22px;
          font-weight: 900;
          text-transform: capitalize;
          display: flex;
          align-items: center;
          // gap: 4;

          .btnRate {
            margin-left: 10px;
            color: #4a4c4d;
            font-family: Gotham;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            padding: 3px 9px;
            border-radius: 5px;
            border: 1px solid #d9d9d9;
            height: auto;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            svg {
              margin-right: 5px;
            }
          }
        }
        &-email {
          display: flex;
          align-items: center;
          color: #6f7174;
          font-size: 14px;
          font-weight: 500;
          gap: 8px;
        }
        &-phonenumber {
          display: flex;
          align-items: center;
          color: #6f7174;
          font-size: 14px;
          font-weight: 500;
          gap: 8px;
        }
        &-cardType {
          display: flex;
          align-items: center;
          color: #002857;
          font-size: 14px;
          font-weight: 500;
          gap: 8px;
          img {
            height: 20px;
          }
        }
      }

      &-second {
        display: flex;
        justify-content: space-between;
        color: #002857;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-bottom: 1.5px solid #999;
        padding-bottom: 30px;
        margin-bottom: 32px;

        &-status {
          &-item {
            display: flex;
            align-items: center;
            margin-bottom: 12px;

            .ant-btn {
              margin-left: 8px;
              &:hover {
                background: none !important;
                cursor: pointer;

                svg {
                  path {
                    fill: #002857;
                  }
                }
              }
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
          &-icon {
            margin-right: 18px;
            width: 15px;
          }
        }

        &-persona {
          display: flex;
          flex-direction: column;
          gap: 20px;
          &-label {
            font-size: 14px;
          }
        }
        &-ltv {
          display: flex;
          flex-direction: column;
          gap: 20px;
          &-label {
            margin: 0;
            font-weight: 700;
            font-size: 19px;
          }
        }
        &-power {
          display: flex;
          flex-direction: column;
          gap: 20px;
          &-label {
            margin: 0;
            font-size: 19px;
            font-weight: 700;
          }
        }
      }

      &-preferences {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px !important;
        width: 100%;
        &-title {
          font-weight: 600;
          color: #002857;
          font-size: 18px;
          font-weight: 600;
          margin: 0;
          margin-bottom: 16px;
        }
        &-preference {
          display: flex;
          align-items: center;
          gap: 20px;
          margin: 0;
          margin-bottom: 10px;
          border-radius: 5px;
          background: rgba(2, 175, 239, 0.25);
          padding: 10px 15px;
          font-size: 16px;
          font-weight: 400;
          color: #02afef;
          &-icon {
            height: 20px;
            width: 20px;

            svg {
              width: 20px !important;
              height: 20px !important;
            }
          }
        }
      }

      &-sliders {
        width: 100%;
      }

      &-purchase {
        &-title {
          font-weight: 600;
          color: #002857;
          font-size: 18px;
          font-weight: 600;
          margin: 0;
          margin-bottom: 12px;
        }
        &-info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 5px;
          border: 2px solid #002857;
          color: #002857;
          font-size: 16px;
          font-weight: 600;
          box-sizing: border-box;
          padding: 12px 16px;
          &-txnID {
            color: #6f7174;
            font-size: 12px;
          }
        }
      }

      &-recommendations {
        &-header {
          display: flex;
          align-items: center;
          margin-bottom: 24px;
          width: 100%;
        }
        &-switch {
          margin-left: auto;
          display: inline-flex;
          align-items: center;

          p {
            color: #6f7174;
            font-family: Gotham;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
          }

          .ant-switch {
            transform: rotate(180deg);
            margin: 0 9px;
          }
          .ant-switch-checked {
            background: #002857;
          }
        }
        &-title {
          font-weight: 600;
          color: #002857;
          font-size: 18px;
          font-weight: 600;
          margin: 0;
        }
        &-cards {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 13px 21px;
          @media (max-width: 480px) {
            gap: 12px;
          }
          &-card {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 10px;
            background: #fff;
            box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
            height: 72px;
            padding: 11px 15px;
            gap: 14px;
            @media (max-width: 480px) {
              height: auto;
              padding: 8px 10px;
            }
            &-image {
              width: 72px;
              height: 72px;
              @media (max-width: 480px) {
                width: 46px;
                height: 46px;
              }
            }
            &-no-image {
              width: 54px;
              height: 54px;
            }
            &-inform {
              flex: 1;
              display: flex;
              flex-direction: column;
              height: 100%;
              &-title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #6f7174;
                font-size: 12px;
                font-weight: 600;
                margin: 0;
                margin-bottom: 32px;
                &-icon {
                  font-size: 16px;
                }
                @media (max-width: 480px) {
                  margin-bottom: 10px;
                }
              }
              &-price {
                color: #002857;
                font-size: 12px;
                margin: 0;
                font-weight: 700;
              }
            }

            // &:nth-child(4) {
            //   background-color: #02afef;
            //   color: #fff !important;

            //   p {
            //     color: #fff;
            //   }
            // }
          }
        }
      }
    }
  }

  .rateAndReviews {
    margin-top: 100px;

    h1 {
      color: #4a4c4d;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 7px;
      margin-top: 0;
    }

    h6 {
      color: #6f7174;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 0;
      margin-bottom: 60px;
    }

    h5 {
      color: #6f7174;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0 0 14px;
    }

    .ant-rate {
      font-size: 26px;
      color: #ffd700;
      margin-bottom: 16px;

      .ant-rate-star-first {
        // color: #999999;
      }
    }

    .viewTabs {
      .ant-tabs {
        .ant-tabs-nav {
          margin: 0 0 30px;
        }

        .ant-tabs-tab {
          padding: 22px 12px;
        }

        .ant-tabs-tab-btn {
          color: rgba(111, 113, 116, 0.5);
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          padding: 0;
        }

        .ant-tabs-tab-active .ant-tabs-tab-btn {
          color: #6f7174;
        }

        .ant-tabs-ink-bar {
          background: #6f7174;
        }
      }
    }

    .listReviews {
      .reviewItem {
        padding: 10px 14px 17px 21px;
        box-sizing: border-box;
        border-radius: 5px;
        background: rgba(2, 175, 239, 0.05);
        margin-bottom: 29px;

        .itemHead {
          display: flex;
          align-items: end;
          margin-bottom: 17px;

          .ant-rate {
            font-size: 16px;
            margin: 0;
          }
          h5 {
            color: #707070;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin: 0 0 0 17px;
          }
          p {
            color: #0050bc;
            text-align: right;
            font-size: 12px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            margin: 0 0 0 auto;
          }
        }
        .comment {
          margin: 0 0 6px;
          color: #6f7174;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
        }
        .readMore {
          color: #0050bc;
          font-size: 10px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
        }
      }

      .viewMoreAction {
        text-align: right;
        margin-bottom: 60px;

        a {
          color: #0050bc;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }

    .viewReviews {
      color: #0050bc;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .rateForm {
      margin-bottom: 30px;
      h5 {
        color: #6f7174;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 0 0 12px;
      }
      p {
        color: #6f7174;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        margin: 0 0 19px;
      }
      .comments {
        margin-top: 64px;

        label {
          color: #6f7174;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          display: block;
          margin: 0 0 23px;
        }
      }
      .btnSubmit {
        border-radius: 5px;
        background: #0050bc;
        padding: 17px;
        height: auto;
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        margin-top: 69px;
      }
    }

    @media (max-width: 768px) {
      margin-top: 77px;

      h1 {
        font-size: 32px;
      }
      h6 {
        font-size: 10px;
      }
      h5 {
        font-size: 13px;
      }

      .ant-rate {
        font-size: 22px;
      }

      .viewTabs {
        .ant-tabs {
          .ant-tabs-tab-btn {
            font-size: 14px;
          }

          .ant-tabs-tab {
            padding: 20px 10px;
          }
        }
      }
    }
  }

  &.mobile {
    padding: 26px 18px 32px 28px;
    @media (max-width: 480px) {
      padding: 16px;
    }
    .drawer-header {
      &-close {
        left: 18px;
      }
      &-state {
        font-size: 13px;
        .offline {
          width: 8px !important;
          height: 8px !important;
        }
        .online {
          width: 8px !important;
          height: 8px !important;
        }
      }
      margin-bottom: 82px;
    }

    .listReviews {
      .reviewItem {
        margin-bottom: 45px;
        .itemHead {
          h5 {
            font-size: 10px;
          }
          p {
            font-size: 10px;
          }
        }
        .comment {
          font-size: 13px;
          margin-bottom: 12px;
        }
        .readMore {
          font-size: 10px;
        }
      }
    }

    .viewReviews {
      font-size: 12px;
    }

    .rateForm {
      h5 {
        font-size: 14px;
        margin: 0 0 15px;
      }
      p {
        font-size: 13px;
        margin: 0 0 17px;
      }

      .comments {
        margin-top: 59px;
        label {
          margin: 0 0 17px;
          font-size: 14px;
        }
      }
      .btnSubmit {
        padding: 14px;
        font-size: 12px;
        margin-top: 80px;
      }
    }

    .drawer-content {
      position: relative;

      .avatar-desktop {
        display: none;
      }
      .avatar-mobile {
        display: inline-block;
      }
      &-avatar {
        position: absolute;
        left: 0;
        width: 75px;
        height: 75px;
        background-size: 75px;
        font-size: 16px;
      }
      &-detail {
        &-commonInfo {
          // margin-left: 102px;
          margin-top: 0px;
          margin-bottom: 64px;
          display: flex;
          align-items: start;
          flex-direction: unset;
          gap: 19px;

          &-box {
          }

          .btnRate {
            font-size: 10px;
          }
          &-name {
            font-size: 16px;
          }
          &-email {
            font-size: 13px;
          }
          &-phonenumber {
            font-size: 13px;
          }
          &-cardType {
            font-size: 13px;
          }
        }
        &-second {
          padding-bottom: 18px;
          margin-bottom: 28px;
          font-size: 14px;
          &-persona {
            gap: 15px;
            // &-label {
            //   font-size: 12px;
            // }
          }
          &-ltv {
            gap: 15px;
            &-label {
              font-size: 16px;
            }
          }
          &-power {
            gap: 15px;
            &-label {
              font-size: 16px;
            }
          }
        }
        &-preferences {
          margin-bottom: 18px;
          &-title {
            font-size: 14px;
            font-weight: 500;
          }
          &-preference {
            font-size: 12px;
            &-icon {
              height: 16px;
              font-size: 16px;
            }
          }
        }
        &-purchase {
          margin-bottom: 28px;
          &-title {
            font-size: 14px;
          }
          &-info {
            padding: 9px 21px;
            font-size: 12px;
            &-txnID {
              font-size: 13px;
            }
          }
        }
        &-recommendations {
          &-title {
            font-size: 14px;
            font-weight: 500;
          }
          &-cards {
            gap: 24px 16px;
            &-card {
              gap: 10px;
              &-image {
                width: 65px;
                height: 65px;
              }
              &-inform {
                &-title {
                  font-size: 13px;
                  &-icon {
                    font-size: 12px;
                  }
                }
                &-price {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
  .slick-dots {
    bottom: -10px !important;

    li {
      width: 10px !important;
      height: 10px;

      button {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background: #fff !important;
        border: 1px solid #002857 !important;
      }
    }
    li.slick-active {
      button {
        background: #002857 !important;
      }
    }
  }
}
