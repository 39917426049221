.login {
  background-color: #0050bc;
  background-image: url("../../assets/img/login-desktop-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  padding: 0px !important;
  min-height: 100vh;
  position: relative;
  .loginContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px;
    margin-bottom: 100px;
    .loginCard {
      background-color: white;
      border-radius: 10px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      .loginCardLeftSide {
        padding: 65px 40px 65px 55px;
        .loginLogo {
          position: relative;
          left: -30px;
        }
      }
      .loginCardRightSide {
        padding: 40px 30px 40px 40px; // top right bottom left
        .loginGraphic {
          max-height: 750px;
        }
      }
    }
  }
}

@media screen and (max-width: 1439px) and (min-width: 1025px) {
  .login {
    .loginContainer {
      padding: 50px;
      .loginCard {
        .loginCardLeftSide {
          padding: 45px 30px 45px 45px;
          .loginLogo {
            left: -25px;
          }
        }
        .loginCardRightSide {
          padding: 20px 30px 20px 30px;
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) and (min-width: 1025px) {
  .login {
    .loginContainer {
      .loginCard {
        .loginCardLeftSide {
          padding-right: 15px;
        }
        .loginCardRightSide {
          padding-left: 20px;
          // padding-top: 45px;
          .loginGraphic {
            height: 630px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .login {
    .loginContainer {
      padding: 50px;
      .loginCard {
        .loginCardLeftSide {
          padding: 65px;
          .loginLogo {
            left: 0px;
            display: flex;
            flex-direction: row;
            justify-content: center;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 699px) {
  .login {
    background-color: white;
    background-image: none;
    .loginContainer {
      padding: 50px 30px 80px 30px;
      padding: 0px;
      padding-top: 50px;
      padding-bottom: 80px;
      .loginCard {
        border-radius: none;
        box-shadow: none;
        margin-bottom: 80px;
        .loginCardLeftSide {
          padding: 0px;
        }
      }
    }
  }
}