.metricsCard {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    height: 130px;
    min-width: 320px;
    .metricsCardAmount {
      font-size: 32px;
      font-weight: 600;
      text-align: center;
    }
    .ratingBoxSegment {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      justify-content: center;
      
      p {
        text-align: center;
        color: rgba(0, 40, 87, 0.6);
        font-size: 12px;
      }
      legend {
        font-size: 14px;
        color: rgba(0, 40, 87, 0.6);
      }
      .rating {
        font-size: 26px;
        font-weight: 500;
        color: #000;
        line-height: 20px;
      }
    }
    .ratingDescription {
      color: #6f7174;
      font-size: 14px;
    } 
  }

  .segmentStatsCard {
    background-color: white;
    padding: 20px 20px 8px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    .cardUpperSection {
      display: flex;
      margin-bottom: 20px;
      .iconBox {
        justify-content: center;
        border: 2px solid #d9d9d9;
        border-radius: 100%;
        height: 40px;
        width: 40px;
        margin-right: 20px;
        svg {
          margin: 10px;
        }
      }
      .percentageChip {
        align-self: center;
        margin-left: auto;
        height: 17px;
        padding: 5px;
      }
      .orangeBadge {
        background-color: #ffedd5 !important;
        color: #fea42e;
      }
      .redBadge {
        background-color: #fbe1e1 !important;
        color: #de2b2b;
      }
      .greenBadge {
        background-color: #e5f4eb !important;
        color: #16b21d;
      }
      .skeletonAvatar {
        margin-top: 14px !important;
      }
      .skeletonHeading {
        padding: 5px;
        width: 100px;
        border-radius: 15px;
      }
      .skeletonChip {
        width: 10%;
        border-radius: 10px;
      }
    }
    .cardLowerSection {
      display: flex;
      svg {
        margin-left: auto;
        margin-top: 3px;
        width: 16px;
        height: 16px;
      }
    }
    .skeletonValue {
      padding: 7px;
      width: 130px;
      border-radius: 15px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media only screen and (max-width: 1024px) and (min-width: 768px) {
    .metricsCard {
      height: 142px;
    }
    .ratingMetricsCard {
      height: 142px;
    }
  }