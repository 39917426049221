.productAffinity {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  .cardHeader {
    padding: 20px;
    display: flex;
    border-bottom: 1px solid #d9d9d9;
  }
  .productAffinityBody {
    padding: 12px;
    display: flex;
    border-bottom: 1px solid #d9d9d9;
    .skeletonAvatar {
      height: 60px;
      border-radius: 12px;
    }
    .skeletonSubHeading {
      padding: 5px;
      width: 60px;
      border-radius: 15px;
    }
    .skeletonText {
      width: 80%;
      border-radius: 10px;
    }
    .skeletonValue {
      padding: 10px;
      width: 40px;
      border-radius: 15px;
      margin-left: auto;
    }
    .productAffinityProfile {
      width: 35%;
      display: flex;
      .productAffinityImg {
        width: 30%;
        img {
          width: 100%;
        }
      }
      .productAffinityNameSection {
        width: 60%;
        padding: 10px;
        :first-child {
          color: #002857;
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 4px;
        }
        :last-child {
          color: #6f7174;
          font-size: 12px;
        }
      }
    }
    .productAffinityRightSide {
      display: flex;
      width: 65%;
      .progressBarSection {
        width: 80%;
        padding: 2px 6px;
        > span {
          margin-bottom: 8px;
          margin-top: 4px;
        }
        .progressBarPriceSection {
          :last-child {
            color: #6f7174;
            font-size: 12px;
          }
        }
      }
      .percentageSection {
        width: 20%;
        padding: 10px;
        text-align: center;
        p {
          font-size: 20px;
          font-weight: 600;
          color: #002857;
          margin-top: 8px;
        }
      }
    }
  }
  .cardFooter {
    padding: 20px;
    display: flex;
  }
}

@media only screen and (max-width: 480px) and (min-width: 320px) {
  .productAffinity {
    .productAffinityBody {
      display: block;
      .productAffinityProfile {
        width: 100%;
        .productAffinityImg {
          width: 20%;
        }
        .productAffinityNameSection {
          width: 80%;
        }
      }
      .productAffinityRightSide {
        width: 100%;
        .progressBarSection {
          width: 80%;
        }
        .percentageSection {
          width: 20%;
        }
      }
    }
  }
}
