.confirmation {
  background-color: #eceff3;
  padding: 0px !important;
  min-height: 100vh;
  position: relative;
  .confirmContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 70px 50px;
    margin-bottom: 100px;
    .heading {
      color: #002857;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .subHeading {
      color: #999999;
      font-weight: 500;
      margin-bottom: 60px;
      text-align: center;
    }
    .startCard {
      background-color: white;
      border-radius: 20px;
      max-width: 700px;
      .MuiLinearProgress-bar {
        background-color: #4bdd78;
      }
      .MuiLinearProgress-root {
        background-color: #d9d9d9;
      }
      .cardHeading {
        color: #002857;
        font-weight: 500;
      }
      .cardText {
        color: #999999;
        font-weight: 500;
      }
      .arrow-icon{
        max-width: 16px !important;
      }
      .cardUpperSection {
        display: flex;
        justify-content: space-between;
        padding: 25px 25px 15px;
      }
      .cardLowerSection {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 35px 25px 25px;
        .cardHeading {
          color: #002857;
          font-weight: 450;
        }
        .cardText {
          color: #999999;
          font-weight: 450;
        }
        .btnBox {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 20px;
          margin-bottom: 40px;
          .subText {
            color: #707070;
            font-size: 10px;
            margin-top: 8px;
          }
        }
        button {
          border-radius: 10px;
          width: 150px;
        }
        .doneIcon {
          display: flex;
          justify-content: center;
          margin-top: 10px;
          margin-bottom: 20px;
          width: 150px;
        }
        .dashboardBtn {
          width: 220px !important;
          padding: 10px;
          font-size: 16px;
          margin-top: 15px;
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .confirmation {
    .confirmContainer {
      padding: 60px 30px;
      .startCard {
        .cardUpperSection {
          padding: 25px 15px 15px;
        }
      }
    }
  }
}

@media screen and (max-width: 700px) and (min-width: 600px) {
  .confirmation {
    .confirmContainer {
      padding: 60px 30px;
      .startCard {
        .cardLowerSection {
          button {
            width: 130px;
          }
        }
      }
    }
  }
}