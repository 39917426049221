.checkIns {
  width: 100%;
  padding: 0 14px;

  .ant-table {
    .ant-table-content {
      table {
        thead {
          tr {
            td,
            th {
              background: #fff !important;
              border-bottom: none;
              font-size: 14px;
              font-weight: 700;
              text-align: left;
              color: #002857;

              &::before {
                display: none;
              }
            }
          }
        }
        tbody {
          tr {
            td {
              border: none;
              font-weight: 500;
              font-size: 14px;
              text-transform: capitalize;
              color: #707070;

              &:first-child {
                text-transform: lowercase;
              }
            }
          }
        }
      }
    }
  }

  .user-name {
    display: flex;
    align-items: center;
    // gap: 12px;
    margin: 0;

    .user-icon {
      box-sizing: border-box;
      padding: 2px;
      font-size: 16px;
      background-color: #002857;
      border-radius: 50%;
      color: #fff;
      border: none;
      margin-right: 12px;
    }

    .avatar {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      overflow: hidden;
      object-fit: fill;
      margin-right: 12px;
    }

    .name {
      width: calc(100% - 32px);
      display: inline-block;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      max-width: 300px;

      @media (max-width: 1600px) {
        max-width: 240px;
      }
      @media (max-width: 1280px) {
        max-width: 160px;
      }
    }
  }

  .view-icon {
    color: #0063bc;
    font-size: 22px;
    cursor: pointer;
    &:hover {
      color: #1380df;
    }
  }
}

.skeletonTable {
  .ant-skeleton-title {
    margin: 0;
  }
}

@media (max-width: 1024px) {
  .checkIns {
    display: none;
  }
}
