button {
  text-transform: none !important;
}

.basicInformation {
  padding: 0px !important;
  min-height: 100vh;
  position: relative;

  .MuiOutlinedInput-root {
    color: #6f7174;
    font-weight: 500;
    border-radius: 10px;
    height: 50px;
  }
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
    color: #c8c8ce;
    font-weight: 500;
  }
  .MuiSvgIcon-root.MuiSelect-icon {
    color: #002857;
    font-size: 35px;
    transform: scaleY(-1);
  }
  
  .cancelModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 10px;
    padding: 30px;
    color: #002857;
    display: flex;
    flex-direction: column;
    align-items: center;
    h6 {
      font-weight: 500;
    }
    button {
      border-radius: 25px;
      width: 150px;
    }
  }

  .infoPageRightSection {
    background-color: #e5f7ff;
    min-height: calc(100% - 140px);
    padding: 70px 80px;
    // margin-bottom: 100px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .headingText {
      font-weight: 500;
      font-size: 14px;
    }
    .normalText {
      font-size: 12px;
      color: #999999;
    }
    .progressGraph {
      .stepText {
        font-size: 12px;
        color: #999999;
      }
    }
  }

  .progressGraphMobile {
    background-color: white;
    padding: 50px 20px 0px 20px;
    .stepperIcon {
      height: 20px;
    }
    .stepperText {
      font-weight: 500;
      color: #999999;
    }
    .activeStepperText
    {
      font-weight: 500;
      color: #002857;
    }
  }
}


@media  screen and (max-width: 550px) and (min-width: 350px) {
  .basicInformation {
    .progressGraphMobile {
      padding: 40px 20px 0px 20px;
      .stepperIcon {
        height: 12px;
      }
      .stepperText {
        font-size: 12px;
      }
      .activeStepperText {
        font-size: 12px;
      }
    }
  }
  
}