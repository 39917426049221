.planTable {
  table {
    border: #02afef solid 1px;
    table-layout: fixed;
    th {
      border: #02afef solid 0.5px;
      color: #002857;
      padding: 20px;
    }
    td {
      border: #02afef solid 0.5px;
      text-align: center;
      color: #002857;
    }
  }

  .cornerCell {
    h6 {
      font-weight: 500;
      color: #002857;
    }
    .subtext {
      color: #999999 !important;
      margin-top: 5px;
    }
  }

  .columnHead {
    display: flex;
    flex-direction: column;
    align-items: center;
    .cardHeading {
      font-weight: 500;
      color: #02afef;
    }
    .cardPrice {
      display: flex;
      align-items: baseline;
      gap: 5px;
      .price {
        font-weight: 500;
        color: #002857;
      }
      .pricePer {
        color: #999999;
        margin-top: 25px;
      }
    }
  }
}

.planTableMobile {
  background-color: white;
  box-shadow: 0px 8px 12px 0px #96dfff8e;
  border-radius: 5px;
  margin-bottom: 20px;

  table {
    th {
      border-bottom: #02afef solid 0.5px;
      color: #002857;
      font-size: 10px;
      padding: 12px 4px;
      width: fit-content;
    }
    td {
      border-bottom: #02afef solid 0.5px;
      text-align: center;
      color: #002857;
      font-size: 10px;
      padding: 10px 5px;
    }
  }

  .tableHeading {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  .columnHeadMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    .cardChip {
      font-weight: 500;
      margin-top: 10px;
      border-radius: 8px;
    }
    .Free {
      background-color: #cceffc !important;
      color: #02afef !important;
    }
    .Business {
      background-color: #ccd4dd;
      color: #002857;
    }
    .Premium {
      background-color: #ccdcf2;
      color: #0050bc;
    }
  }
}

@media screen and (max-width: 1400px) and (min-width: 1200px) {
  .planTable {
    table {
      th {
        padding: 10px;
      }
    }
  }
}
