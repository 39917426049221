.optOutConfirm {
  width: 100%;
  height: 100vh;
  overflow: hidden;

  &-content {
    height: calc(100vh - 100px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    box-sizing: border-box;

    @media (max-width: 768px) {
      height: calc(100vh - 180px);
    }

    &-detail {
      text-align: center;
      img {
        max-width: 346px;
        width: 100%;
        margin: 0 auto;

        @media (max-width: 768px) {
          max-width: 250px;
        }

        @media (max-width: 480px) {
          max-width: 180px;
        }
      }
      &-title {
        color: #002857;
        // font-family: Plus Jakarta Sans;
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 51px;
        margin-bottom: 13px;
        
        @media (max-width: 768px) {
          font-size: 32px;
        }
        
        @media (max-width: 480px) {
          font-size: 26px;
          margin-top: 30px;
        }
      }
      &-text {
        color: #6f7174;
        // font-family: Metropolis;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 93px;
        
        @media (max-width: 768px) {
          font-size: 14px;
          margin-bottom: 60px;
        }
        @media (max-width: 480px) {
          margin-bottom: 30px;
        }
      }
      &-button {
        color: #0050bc;
        // font-family: Metropolis;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 450px;
        height: 50px;
        border-radius: 10px;
        border: 1px solid #0050bc;
        background: #fff;

        @media (max-width: 768px) {
          width: 360px;
          font-size: 16px;
          height: 42px;
        }

        @media (max-width: 480px) {
          width: 100%;
          font-size: 16px;
          height: 42px;
        }
      }
    }
  }
}
