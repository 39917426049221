.newPassword {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 60px;
    .formTitle {
      color: #002857;
      font-weight: 500;
      font-size: 20px;
      text-align: center;
      margin-bottom: 70px;
    }
    .formHead {
      color: #002857;
      font-weight: 500;
      font-size: 18px;
    }
    .newPasswordButton {
      background-color: #0050bc;
      margin-top: 60px;
      margin-bottom: 10px;
      width: 450px;
      font-size: 18px;
      border-radius: 10px;
      padding: 10px;
    }
    .MuiOutlinedInput-root {
      height: 45px;
      color: #6f7174;
      font-weight: 500;
      border-radius: 10px;
    }
    input {
      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 100px white inset;
      }
    }
  }
  
  @media screen and (min-width: 1536px) {
      .newPassword {
          .newPasswordButton {
          width: 400px;
        }
      }
    }
  
  @media screen and (max-width: 1439px) and (min-width: 1025px) {
    .newPassword {
      .newPasswordButton {
        width: 350px;
      }
    }
  }
  
  @media screen and (max-width: 1199px) and (min-width: 1025px) {
    .newPassword {
      margin-top: 40px;
      .formTitle {
        margin-bottom: 50px;
      }
      .newPasswordButton {
        margin-top: 40px;
        width: 300px;
      }
    }
  }
  
  @media screen and (max-width: 1024px) {
    .newPassword {
      margin-top: 50px;
      .formTitle {
        margin-bottom: 85px;
      }
      .newPasswordButton {
        margin-top: 50px;
      }
    }
  }
  
  @media screen and (max-width: 529px) {
    .newPassword {
      .newPasswordButton {
        width: 370px;
      }
    }
  }
  @media screen and (max-width: 400px) {
    .newPassword {
      .newPasswordButton {
        width: 300px;
      }
    }
  }
  