.infoForm {
  background-color: white;
  padding: 70px 80px;
  // margin-bottom: 100px;
  .MuiFormHelperText-root {
    color: red;
  }
  input {
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 100px white inset;
    }
  }
  .heading {
    color: #002857;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .subHeading {
    color: #999999;
    font-weight: 500;
    margin-bottom: 60px;
  }
  .formItem {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 8px;
    
    .formHead {
      color: #002857;
      font-weight: 500;
      font-size: 18px;
    }
    .formCheckbox {
      color: #6f7174;
      font-size: 16px;
    }
  }
  .formButtons {
    margin-top: 70px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 50px;
    button {
      padding: 10px;
      width: 200px;
      border-radius: 10px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .infoForm {
    padding: 50px 20px;
    // margin-bottom: 50px;
  }
}

@media screen and (max-width: 500px) {
  .infoForm {
    .formItem {
      .formCheckbox {
        font-size: 14px !important;
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .infoForm {
    .formItem {
      .mobileField {
        margin-bottom: 21px;
      }
    }
  }
}
