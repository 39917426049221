.persona {
  display: flex;
  align-items: center;
  width: fit-content;
  box-sizing: border-box;
  padding: 5px 10px;
  border-radius: 4px;
  text-transform: capitalize;
  margin: 0;
  font-size: 14px;
  line-height: normal;
  // height: 25px;

  @media (max-width: 600px) {
    font-size: 12px;
  }

  &.luxury {
    background: rgba(254, 164, 46, 0.2);
    color: #fea42e;
  }
  &.exclusive {
    background: rgba(255, 95, 93, 0.2);
    color: #e96969;
  }
  &.discount-driven {
    background: rgba(125, 0, 183, 0.2);
    color: #7d00b7;
  }
  &.discount {
    background: rgba(125, 0, 183, 0.2);
    color: #7d00b7;
  }
  &.needs-based {
    background: rgba(2, 175, 239, 0.2);
    color: #02afef;
  }
  &.research-oriented {
    background: rgba(125, 198, 153, 0.2);
    color: #7dc699;
  }
  &.trend-setter {
    background: rgba(215, 207, 15, 0.2);
    color: #d7cf0f;
  }
  &.brand-conscious {
    background: rgba(227, 0, 183, 0.2);
    color: #e300b7;
  }
  &.organizational {
    background: rgba(64, 70, 246, 0.2);
    color: #4046f6;
  }
  &.needs {
    background: rgba(2, 175, 239, 0.2);
    color: #02afef;
  }
  &.need-based {
    background: rgba(2, 175, 239, 0.2);
    color: #02afef;
  }
  &.unknown {
    background: rgba(153, 153, 153, 0.3);
    color: #6f7174;
  }
  &.standard {
    background: rgba(153, 153, 153, 0.3);
    color: #6f7174;
  }
  &.seasonal {
    background: rgba(70, 227, 227, 0.2);
    color: #46e3e3;
  }
  &.seasonal-buyer {
    background: rgba(70, 227, 227, 0.2);
    color: #46e3e3;
  }
  &.impluse {
    background: rgba(221, 25, 42, 0.2);
    color: #dd192a;
  }
  &.impulse {
    background: rgba(221, 25, 42, 0.2);
    color: #dd192a;
  }
  &.social-driven {
    background: rgba(16, 138, 112, 0.2);
    color: #108a70;
  }
  &.social {
    background: rgba(16, 138, 112, 0.2);
    color: #108a70;
  }
  &.habitual {
    background: rgba(130, 132, 52, 0.2);
    color: #828434;
  }
  &.trendsetter {
    background: rgba(188, 62, 199, 0.2);
    color: #bc3ec7;
  }
  &.trendsetter-innovation {
    background: rgba(188, 62, 199, 0.2);
    color: #bc3ec7;
  }
  &.convenience {
    background: rgba(103, 96, 181, 0.2);
    color: #6760b5;
  }
  &.ethical {
    background: rgba(243, 28, 157, 0.2);
    color: #f31c9d;
  }
  &.ehtical-sustainable {
    background: rgba(243, 28, 157, 0.2);
    color: #f31c9d;
  }
  &.emotional {
    background: rgba(30, 6, 6, 0.2);
    color: #1e0606;
  }
  &.environmental {
    background: rgba(239, 93, 11, 0.2);
    color: #ef5d0b;
  }
  &.loyal {
    background: rgba(136, 54, 217, 0.2);
    color: #8836d9;
  }
}

.card-el {
  &.luxury {
    background: rgba(254, 164, 46, 0.2);
    color: #fea42e;
  }
  &.exclusive {
    background: rgba(255, 95, 93, 0.2);
    color: #e96969;
  }
  &.discount-driven {
    background: rgba(125, 0, 183, 0.2);
    color: #7d00b7;
  }
  &.discount {
    background: rgba(125, 0, 183, 0.2);
    color: #7d00b7;
  }
  &.needs-based {
    background: rgba(2, 175, 239, 0.2);
    color: #02afef;
  }
  &.research-oriented {
    background: rgba(125, 198, 153, 0.2);
    color: #7dc699;
  }
  &.trend-setter {
    background: rgba(215, 207, 15, 0.2);
    color: #d7cf0f;
  }
  &.brand-conscious {
    background: rgba(227, 0, 183, 0.2);
    color: #e300b7;
  }
  &.organizational {
    background: rgba(64, 70, 246, 0.2);
    color: #4046f6;
  }
  &.needs {
    background: rgba(2, 175, 239, 0.2);
    color: #02afef;
  }
  &.need-based {
    background: rgba(2, 175, 239, 0.2);
    color: #02afef;
  }
  &.unknown {
    background: rgba(153, 153, 153, 0.3);
    color: #6f7174;
  }
  &.standard {
    background: rgba(153, 153, 153, 0.3);
    color: #6f7174;
  }
  &.seasonal {
    background: rgba(70, 227, 227, 0.2);
    color: #46e3e3;
  }
  &.seasonal-buyer {
    background: rgba(70, 227, 227, 0.2);
    color: #46e3e3;
  }
  &.impluse {
    background: rgba(221, 25, 42, 0.2);
    color: #dd192a;
  }
  &.impulse {
    background: rgba(221, 25, 42, 0.2);
    color: #dd192a;
  }
  &.social-driven {
    background: rgba(16, 138, 112, 0.2);
    color: #108a70;
  }
  &.social {
    background: rgba(16, 138, 112, 0.2);
    color: #108a70;
  }
  &.habitual {
    background: rgba(130, 132, 52, 0.2);
    color: #828434;
  }
  &.trendsetter {
    background: rgba(188, 62, 199, 0.2);
    color: #bc3ec7;
  }
  &.trendsetter-innovation {
    background: rgba(188, 62, 199, 0.2);
    color: #bc3ec7;
  }
  &.convenience {
    background: rgba(103, 96, 181, 0.2);
    color: #6760b5;
  }
  &.ethical {
    background: rgba(243, 28, 157, 0.2);
    color: #f31c9d;
  }
  &.ehtical-sustainable {
    background: rgba(243, 28, 157, 0.2);
    color: #f31c9d;
  }
  &.emotional {
    background: rgba(30, 6, 6, 0.2);
    color: #1e0606;
  }
  &.environmental {
    background: rgba(239, 93, 11, 0.2);
    color: #ef5d0b;
  }
  &.loyal {
    background: rgba(136, 54, 217, 0.2);
    color: #8836d9;
  }
}
