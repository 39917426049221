.topSellerCard {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  .cardHeader {
    padding: 20px;
    display: flex;
    border-bottom: 1px solid #d9d9d9;
  }
  .heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 18px;
  }
  .topSellerBody {
    border-bottom: 0.5px solid #d9d9d9;
    padding: 23px 12px;
    display: flex;
    align-items: center;
    .topSellerLeftSide {
      display: flex;
      width: 30%;
    }
    .topSellerRightSide {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 70%;
    }
    .skeletonAvatar {
      height: 55px;
      width: 55px;
    }
    .skeletonSubHeading {
      padding: 5px;
      width: 80px;
      border-radius: 15px;
    }
    .skeletonText {
      width: 70px;
      border-radius: 10px;
    }
    .skeletonValue {
      padding: 10px;
      width: 30px;
      border-radius: 15px;
      margin-left: auto;
    }
  }
  // .MuiPaginationItem-root {
  //   color: #999999;
  //   font-weight: 500;
  //   border: 1px solid #f4f4f4;
  //   border-radius: 5px;
  //   background-color: white;
  //   .Mui-selected {
  //     background-color: white;
  //     color: #002857;
  //   }
  //   .MuiSvgIcon-root {
  //     fill: #002857;
  //   }
  // }
  .cardFooter {
    padding: 20px;
    display: flex;
  }
}

@media only screen and (max-width: 480px) and (min-width: 320px) {
  .topSellerCard {
    .topSellerBody {
      margin-top: 10px;
      display: block;
      .topSellerLeftSide {
        display: flex;
        width: 100%;
      }
      .topSellerRightSide {
        margin-top: 20px;
        width: 100%;
      }
    }
  }
}
@media only screen and (max-width: 650px) and (min-width: 480px) {
  .topSellerCard {
    .topSellerBody {
      .skeletonSubHeading {
        padding: 5px;
        width: 50px;
        border-radius: 15px;
      }
      .skeletonText {
        width: 60px;
        border-radius: 10px;
      }
      .skeletonValue {
        padding: 10px;
        width: 20px;
        border-radius: 15px;
        margin-left: auto;
      }
    }
  }
}
@media only screen and (max-width: 1800px) and (min-width: 1024px) {
  .topSellerCard {
    .topSellerBody {
      padding: 18px 12px;
    }
  }
}
@media only screen  and (min-width: 1535px) and (max-width: 1610px) {
  .topSellerCard {
    .topSellerBody {
      height: 72px;
    }
  }
}
@media only screen and (min-width: 1610px) and (max-width: 1691px) {
  .topSellerCard {
    .topSellerBody {
      height: 68px;
    }
  }
}
@media only screen and (min-width: 1691px) and (max-width: 1800px) {
  .topSellerCard {
    .topSellerBody {
      height: 60px;
    }
  }
}
