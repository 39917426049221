.infoForm {
  background-color: white;
  padding: 70px 80px;
  // margin-bottom: 100px;
  .MuiFormHelperText-root {
    color: red;
  }
  input {
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 100px white inset;
    }
  }
  .heading {
    color: #002857;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .subHeading {
    color: #999999;
    font-weight: 500;
    margin-bottom: 60px;
  }
  .formItem {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 8px;
    .formHead {
      color: #002857;
      font-weight: 500;
      font-size: 18px;
    }
    .formCheckbox {
      color: #6f7174;
      font-size: 16px;
    }
    .mobileFieldLocations {
      margin-bottom: 24px;
    }
  }
  .formButtons {
    margin-top: 70px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 50px;
    button {
      padding: 10px;
      width: 200px;
      border-radius: 10px;
    }
  }
}

@media screen and (max-width: 1026px) {
  .infoForm {
    padding: 50px 20px;
    // margin-bottom: 50px;
  }
}

@media screen and (max-width: 1306px) {
  .infoForm {
    .formItem {
      .formHead {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .infoForm {
    .formItem {
      .formHead {
        font-size: 18px;
      }
    }
  }
}

@media screen and (max-width: 795px) {
  .infoForm {
    .formItem {
      .formHead {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 3280px) and (min-width: 1541px) {
  .infoForm {
    .formItem {
      .mobileFieldLocations {
        margin-bottom: 0px;
      }
    }
  }
}

@media screen and (max-width: 1200px) and (min-width: 946px) {
  .infoForm {
    .formItem {
      .mobileFieldLocations {
        margin-bottom: 0px;
      }
    }
  }
}
@media screen and (max-width: 674px) {
  .infoForm {
    .formItem {
      .mobileFieldWebsite {
        margin-bottom: 24px;
      }
    }
  }
}
@media screen and (max-width: 541px) {
  .infoForm {
    .formItem {
      .formHead {
        font-size: 14px;
      }
      .mobileFieldLocations {
        margin-bottom: 19px;
      }
    }
  }
}
@media screen and (max-width: 486px) {
  .infoForm {
    .formItem {
      .mobileFieldEntity {
        margin-bottom: 21px;
      }
    }
  }
}
@media screen and (max-width: 390px) and (min-width: 361px) {
  .infoForm {
    .formItem {
      .mobileFieldLegal {
        margin-bottom: 21px;
      }
    }
  }
}
@media screen and (max-width: 393px) {
  .infoForm {
    .formItem {
      .mobileFieldWebsite {
        margin-bottom: 42px;
      }
    }
  }
}
@media screen and (max-width: 384px) {
  .infoForm {
    .formItem {
      .mobileFieldLocations {
        margin-bottom: 20px;
      }
    }
  }
}
@media screen and (max-width: 372px) {
  .infoForm {
    .formItem {
      .mobileFieldPhone {
        margin-bottom: 21px;
      }
    }
  }
}
