.settingOrganization {
  margin-top: 30px;

  .orgHeader {
    display: flex;
    gap: 20px;
    align-items: start;

    .btnLocation {
      margin-left: auto;
      display: inline-flex;
      gap: 9px;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      background: #002857;
      border: none !important;
      height: 35px;
      width: 127px;
      color: #fff;
      font-family: "Gotham", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-left: auto;

      &:hover {
        background: #002857;
        border: none !important;
        opacity: 0.7;
      }
    }
  }
}

.modalLocation {
  .headerPopup {
    text-align: center;

    .userDetail {
      display: inline-flex;
    }

    h3.title {
      color: #002857;
      font-family: "Gotham", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0 0 47px;
    }
  }

  .ant-modal {
    width: 950px !important;

    .ant-modal-content {
      padding: 65px 70px 59px;

      .ant-modal-close-icon {
        color: #002857;
      }
    }
  }
}
