.pricePlanCards {
  .cardText {
    font-size: 15px;
    color: #002857;
  }
  button {
    padding: 8px;
    width: 200px;
    border-radius: 10px;
    background-color: #02afef;
    box-shadow: none;
    margin-top: 8px;
    margin-bottom: 4px;
  }

  .pricePlanTopCards {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin-bottom: 40px;
    .isSelected {
      border: #02afef solid 2px;
      // min-width: 310px !important;
      // min-height: 700px !important;
      // transition: min-width 0.4s ease-in-out, min-height 0.4s ease-in-out;
    }

    .planCard {
      background-color: white;
      box-shadow: 4px 8px 12px #96dfff8e;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      min-width: 270px;
      max-width: 310px;
      min-height: 580px;
      .cardUpperSection {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 30px 15px 20px;
        gap: 15px;
        .cardChip {
          padding: 8px;
          background-color: #d5f3ff;
          color: #02afef;
          font-weight: 500;
          margin-bottom: 20px;
        }
        .cardHeading {
          font-weight: 600;
          color: #02afef;
        }
        .cardPrice {
          display: flex;
          align-items: baseline;
          gap: 5px;
          .price {
            font-weight: 500;
            color: #002857;
          }
          .pricePer {
            color: #999999;
          }
        }
      }
      .cardLowerSection {
        padding: 20px 15px 30px;
        .bulletPoint {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 15px;
          margin-bottom: 15px;
        }
        .imgEnd {
          align-self: end;
        }
      }
    }
  }
  .pricePlanBottomCard {
    background-color: white;
    box-shadow: 4px 8px 12px #96dfff8e;
    border-radius: 10px;
    border: #02afef solid 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 30px;
    margin-bottom: 60px;
    .cardHeading {
      font-weight: 500;
      color: #002857;
      margin-bottom: 5px;
    }
    .cardPoint {
      font-weight: 500;
      color: #02afef;
    }

    button {
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 1400px) and (min-width: 1200px) {
  .pricePlanCards {
    .cardText {
      font-size: 14px;
    }
    button {
      padding: 6px;
      width: 150px;
      margin-top: 8px;
      margin-bottom: 8px;
    }

    .pricePlanTopCards {
      gap: 20px;
      margin-bottom: 40px;
      .isSelected {
        // min-width: 260px !important;
        // min-height: 600px !important;
      }

      .planCard {
        min-width: 240px;
        min-height: 500px;

        .cardUpperSection {
          padding: 25px 10px 10px;
          gap: 5px;
          .cardChip {
            padding: 5px;
            margin-bottom: 10px;
          }
          .cardHeading {
            font-weight: 500;
          }
          .cardPrice {
            .price {
              font-size: 30px;
            }
          }
        }
        .cardLowerSection {
          padding: 15px 15px;
          .bulletPoint {
            gap: 10px;
            margin-bottom: 12px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 930px) {
  .pricePlanTopCards {
    display: flex;
    flex-direction: column;
  }
  .divider {
    width: 80%;
    align-self: center;
  }
  .pricePlanBottomCard {
    flex-direction: column !important;
    align-items: center;
    gap: 30px;
  }
}
