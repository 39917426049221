.footer {
  display: flex;
  width: 100%;
  height: 100px;
  background: #002857;
  text-align: center;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  padding: 14px 20px;
  bottom: 0;

  // font-weight: 500;
  font-size: 14px;
  color: #fff;
  user-select: none;

  &-left {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  &-right {
    font-family: "Lato-light";

    p {
      font-weight: 300;
    }
  }

  &.tablet {
    height: auto !important;
  }

  &.mobile {
    flex-direction: column;
    height: auto !important;
    .footer-left {
      flex-direction: column;
      gap: 6px;
      margin-bottom: 30px;
      .footer-logo {
        height: 37px;
      }
      p {
        margin: 0;
        font-size: 12px;
      }
    }
    .footer-right {
      p {
        font-size: 12px;
        margin: 0;
      }
    }
  }
  &.tablet {
    height: 208px;
    .footer-copyright {
      font-size: 14px;
    }
  }
}
