.popupPicker {
  .popupPickerContent {
    .rdrDayToday {
    }

    .rdrDateDisplayWrapper {
      display: none;
    }
    .rdrMonthAndYearWrapper {
      padding-top: 0;
    }

    .rdrSelected,
    .rdrInRange,
    .rdrStartEdge,
    .rdrEndEdge {
      top: 2px;
      bottom: 2px;
    }

    .rdrDayNumber {
      span {
        color: #151515;
        font-family: Inter, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
      }
    }

    .rdrDayPassive {
      .rdrDayNumber {
        span {
          opacity: 0.2;
        }
      }
    }

    .rdrInRange {
      color: #e6f1fe !important;
    }

    .rdrDayNumber {
      span {
        color: #151515 !important;
      }
    }

    .rdrStartEdge {
      // border-radius: ;
    }

    .rdrStartEdge,
    .rdrEndEdge {
      color: #02afef !important;
    }

    .rdrDayToday {
      .rdrStartEdge {
        // color: #fff !important;
      }

      .rdrDayNumber {
        span {
          &:after {
            background: #02afef !important;
          }
        }
      }
    }

    .rdrDayDisabled {
      .rdrDayNumber {
        opacity: 0.5;

        span {
          // color: #aeb9bf !important;
        }
      }
    }

    .actions {
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      gap: 20px;
      padding-top: 16px;
      border-top: 1px solid #eff2f7;

      .btnReset {
      }
      .btnSubmit {
        background: #002857 !important;
        border-color: #002857 !important;
      }
    }
  }
}

.btnCalendar {
  border: none;
  background: #fff;
  padding: 8px 16px;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: auto;
  border-radius: 5px;

  .dates {
    color: #002857;
    // font-family: Gotham;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 100%;
  }

  .iconCalendar {
    margin-left: 24px;
  }
}

@media (max-width: 991px) {
  .popupPicker {
    .popupPickerContent {
      .rdrDefinedRangesWrapper {
        display: none;
      }
    }
  }
}
