.banner {
  display: flex;
  flex-direction: row;
  background-color: #02afef;
  background-image: url("../../../../assets/img/individuals-banner-bg.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  height: 100px;
  align-items: center;
  justify-content: space-evenly;

  .bannerText {
    color: white;
    .highlighted-text {
      color: #002857;
    }
  }
  .bannerButton {
    background-color: white;
    color: #02afef;
    font-size: 18px;
    border-radius: 5px;
    padding: 6px 30px;
    box-shadow: none;
  }
}

@media screen and (max-width: 999px) {
  .banner {
    background-image: url("../../../../assets/img/individuals-banner-mobile-bg.svg");
    flex-direction: column;
    height: 200px;
    justify-content: space-evenly;
    align-items: center;
    padding: 0px 50px;
    .bannerText {
      text-align: center;
    }
    .bannerButton {
      padding: 6px 35px;
      border-radius: 30px;
    }
  }
}

@media screen and (max-width: 477px) {
  .banner {
    .bannerText {
      font-size: 18px;
    }
    .bannerButton {
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 449px) {
  .banner {
    padding: 0px 30px;
  }
}
