.expanded {
  z-index: 11;
  position: relative;
  bottom: -50%;
  border-radius: 5px;
  transform: translateY(-100%);
  margin-bottom: -100%;
  height: min-content;
}

.fullViewCard {
  max-width: 100%;
}
