.showFilter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 30px;
}

.bold-100 {
  font-weight: 100 !important;
}
.bold-200 {
  font-weight: 200 !important;
}
.bold-300 {
  font-weight: 300 !important;
}
.bold-400 {
  font-weight: 400 !important;
}
.bold-500 {
  font-weight: 500 !important;
}
.bold-600 {
  font-weight: 600 !important;
}
.bold-700 {
  font-weight: 700 !important;
}
.bold-800 {
  font-weight: 800 !important;
}
.bold-900 {
  font-weight: 900 !important;
}

.blue {
  color: #002857;
}

.light-gray {
  color: #C8C8CE;
}
.gray {
  color: #999999 !important;
}

.dark-gray {
  color: #6f7174;
}

.darker-gray {
  color: #4a4c4d;
}

.light-blue {
  color: #02afef;
}

.green {
  color: #16b21d;
}

.yellow {
  color: #fea42e;
}

.red {
  color: #de2b2b;
}
