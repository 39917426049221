.settingsPage {
  text-align: left;
  width: 100%;
  background: #fff;
  border-radius: 5px 5px 0 0;
  padding: 53px 30px;
  min-height: calc(100vh - 272px);
  box-sizing: border-box;

  h1.title {
    color: #002857;

    // font-family: Gotham;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    margin: 0 0 21px;
  }

  .ant-tabs {
    .ant-tabs-nav {
      &::before {
        border: none;
      }
      .ant-tabs-nav-wrap {
      }

      .ant-tabs-ink-bar {
        background: #02afef;
        height: 3px;
      }

      .ant-tabs-tab {
        padding: 6px 0;
        margin-left: 100px;

        &:first-child {
          margin-left: 0;
        }
        .ant-tabs-tab-btn {
          color: #999;
          // font-family: "Plus Jakarta Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;
        }
      }

      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: #002857;
          font-weight: 700;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .settingsPage {
    .ant-tabs {
      .ant-tabs-nav {
        .ant-tabs-tab {
          margin-left: 30px;

          .ant-tabs-tab-btn {
            font-size: 14px;
          }
        }
      }
    }
  }
}
