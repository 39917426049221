.authenticate {
  background-image: linear-gradient(#b8e3f3, #e9f2f5);
  padding: 0px !important;
  min-height: 100vh;
  position: relative;
  .authContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 150px 50px;
    margin-bottom: 100px;
    .desktopLogoContainer {
      position: absolute;
      top: 90px;
      z-index: 1;
      .desktopLogo {
        height: 100px;
      }
    }
    .authCard {
      max-width: 600px;
      padding: 60px 40px 30px;
      background-color: white;
      border-radius: 10px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      .innerBox {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .cardHeading {
        color: #002857;
        font-weight: 600;
        margin-bottom: 10px;
      }
      .cardSubHeading {
        color: #999999;
        font-weight: 500;
        text-align: center;
      }
      .cardEmail {
        color: #002857;
        font-weight: 500;
        margin-bottom: 80px;
      }

      .otpBox {
        margin-bottom: 60px;
        .MuiFormControl-root {
          width: 30px !important;
          border-bottom: 2px solid #d9d9d9 !important;
          position: static;
        }
        .MuiFormControl-root > div {
          position: static;
        }
        input {
          font-size: 32px;
        }
        .MuiInputBase-root.MuiInput-root::after {
          border-bottom: 2px solid #02afef !important;
        }
        .MuiInputBase-root.MuiInput-root::before {
          border-bottom: 2px solid #d9d9d9 !important;
        }
        .MuiOtpInput-Box {
          justify-content: center;
        }
      }

      .cardLowerSection {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        .highlight {
          color: #02afef;
          font-weight: 450;
          text-decoration: none;
          cursor: pointer !important;
        }

        button {
          padding: 10px;
          width: 150px;
          border-radius: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 769px) {
  .authenticate {
    background-image: none;
    background-color: white;
    .authContainer {
      padding: 40px;
      align-items: flex-start;
      .desktopLogoContainer {
        position: relative;
        top: auto;
        z-index: auto;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 70px;
        .desktopLogo {
          height: 70px;
        }
      }
      .authCard {
        width: 100%;
        padding: 0px;
        border-radius: none;
        box-shadow: none;
        display: flex;
        flex-direction: column;
        .innerBox {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
        .cardHeading {
          color: #002857;
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 10px;
        }
        .cardSubHeading {
          color: #6f7174;
          font-size: 14px;
          font-weight: 500;
          text-align: start;
          margin-bottom: 20px;
        }

        .otpBox {
          margin-bottom: 80px;
          input {
            font-size: 14px;
          }
          .codeFieldText {
            color: #6f7174;
            font-weight: 600;
            margin-bottom: 5px;
          }
          .focused {
            color: #02afef !important;
          }

          .MuiFormControl-root {
            width: 100% !important;
          }
          .MuiOutlinedInput-root {
            font-size: 14px;
            height: 45px;
            color: #6f7174;
            font-weight: 500;
            fieldset {
              border: 2px solid #02afef;
            }
            input {
              display: "none";
            }
          }
        }

        .cardLowerSection {
          display: flex;
          flex-direction: column;
          width: 100%;
          align-items: center;
          gap: 20px;
          button {
            padding: 10px;
            width: 250px;
            border-radius: 8px;
          }
        }
      }
    }
  }
}

@media only screen and (width: 1024px) and (height: 600px) {
  .authenticate {
    .authContainer {
      .authCard {
        .otpBox {
          .MuiInputBase-root.MuiInput-root::after {
            border-bottom: none !important;
          }
          .MuiInputBase-root.MuiInput-root::before {
            border-bottom: none !important;
          }
        }
      }
    }
  }
}
