.chartCard {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  .chartHeader {
    padding: 20px 20px 0px 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .legendBox {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
    }
  }
  .circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
  .chartAmount {
    padding: 0px 20px 0px 40px;
    font-size: 28px;
    font-weight: 700;
    color: #002857;
  }
  .chartCardBody {
    // .MuiChartsAxis-directionY {
    //   display: none;
    // }
    .MuiChartsLegend-root {
      display: none;
    }
    .MuiChartsAxis-tickLabel {
      fill: #808080 !important;
    }
    .MuiChartsAxis-directionX {
      // line {
      //   display: none;
      // }
      text {
        font-weight: 500;
      }
    }
  }
  .skeletonCard {
    min-height: 300px !important;
  }
  .skeletonHeading {
    padding: 10px;
    width: 100px;
    border-radius: 15px;
  }
  .skeletonChart {
    width: 95%;
    height: 218px;
    margin-top: 10px;
  }
}
