.menuConnect {
  .ant-btn {
    margin-left: auto;
    border-radius: 5px;
    background: rgba(111, 113, 116, 0.15);
    color: #6f7174;
    font-family: Gotham;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    height: auto;
    padding: 8px;
    width: 90px;
    box-shadow: none;
    border: none !important;

    &:hover {
      background: rgba(111, 113, 116, 0.15) !important;
      color: #6f7174 !important;
      opacity: 0.7;
    }
  }
}

.connectionsNew {
  text-align: left;
  width: 100%;

  &-content {
    width: 100%;

    h1 {
      color: #002857;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      margin: 0 0 35px;
    }

    &-connect {
      &-item {
        margin-bottom: 18px;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
        width: 100%;
        height: 65px;
        display: flex;
        align-items: center;
        padding: 12px 15px;
        box-sizing: border-box;

        &-left {
          width: auto;
          display: inline-flex;
          align-items: center;
          height: 100%;
          overflow: hidden;

          &-checked {
            cursor: pointer;
            opacity: 0.7;
            width: 30px;
            margin-right: 17px;

            .ant-radio {
              font-size: 20px;

              .ant-radio-inner {
                width: 30px;
                height: 30px;
                border: 2px solid #6f7174;
              }
            }
          }

          &-logo {
            display: inline-flex;
            align-items: center;
            // margin: 0 33px;
            box-sizing: border-box;
            max-width: 120px;
            height: 100%;
            margin-right: 20px;

            img {
              display: inline-flex;
              width: auto;
              max-height: 100%;
              max-width: 100%;
            }
          }

          &-title {
            margin: 0;
            color: #707070;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
            width: auto;
          }
        }

        &-action {
          display: inline-flex;
          align-items: center;
          justify-content: end;
          margin-left: auto;
          width: 240px;

          &-btn_connect {
            margin-left: auto;
            border-radius: 5px;
            background: rgba(0, 80, 188, 0.25);
            color: #0050bc;
            font-family: Gotham;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            height: auto;
            padding: 9px 30px;
            box-shadow: none;
            border: none !important;
          }

          &-btn_more {
            border: none;
            padding: 0;
            box-shadow: none;
            width: 25px !important;
            height: 25px !important;
            margin-left: 15px;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: 44px 16px;
    box-sizing: border-box;

    &-content {
      &-connect {
        &-item {
          height: 60px;

          &-left {
            width: calc(100% - 160px);

            &-title {
              display: inline-block;
              max-width: 180px;
              white-space: nowrap;
              overflow: hidden !important;
              text-overflow: ellipsis;
            }

            &-logo {
              max-height: 25px;
              img {
                min-width: 20px;
              }
            }
          }

          &-action {
            width: 160px;

            &-btn_connect {
              padding: 6px 14px;
              border-radius: 5px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 480px) {
    &-content {
      h1 {
        font-size: 16px;
        margin-bottom: 23px;
      }
    }
  }

  @media (max-width: 376px) {
    &-content {
      &-connect {
        &-item {
          &-left {
            &-title {
              display: none;
            }
          }
        }
      }
    }
  }
}
