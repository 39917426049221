.customeCarousel {
    .sliderBox {
      // margin-bottom: 40px;
    }
    .ant-carousel {
      .slick-track {
        gap: 30px;
        padding-bottom: 0px !important;
      }
      .slick-list {
        padding: 0 10px;
        // width: calc(100% + 20px);
        margin-left: 0px !important;
        // margin-right: -10px;
      }
      .slick-dots {
        bottom: 0;
        li {
          width: 8px;
          height: 8px;
          button {
            height: 100%;
            border-radius: 50%;
          }
        }
        li.slick-active {
          button {
            background: #002857 !important;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 540px){
    .customeCarousel {
      .sliderBox {
        // margin-bottom: 40px;
      }
      .slick-slide {
        max-width: fit-content;
      }
      .ant-carousel {
        .slick-track {
          gap: 30px;
          padding-bottom: 0px !important;
        }
        .slick-list {
          padding: 0 10px;
          // width: calc(100% + 20px);
          margin-left: -10px !important;
          // margin-right: -10px;
        }
        .slick-dots {
          bottom: 0;
          li {
            width: 8px;
            height: 8px;
            button {
              height: 100%;
              border-radius: 50%;
            }
          }
          li.slick-active {
            button {
              background: #002857 !important;
            }
          }
        }
      }
  
  }
}

// .customCarousel .ant-carousel,
// .customCarousel .slick-slider,
// .customCarousel .slick-list,
// .customCarousel .slick-track {
//   height: 100% !important;
// }

// .customCarousel .slick-slide > div:first-child {
//   height: 100% !important;
//   width:10px !important;
// }

