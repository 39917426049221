.individualCustomerCard {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px 0px;
  .customerTableUpperSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 18px;
    padding-bottom: 16px;
    .chip {
      margin-left: 30px;
      background-color: #eaebec;
      color: #6f7174;
    }
  }
  .skeletonCell {
    padding: 12px;
    width: 80px;
    border-radius: 12px;
  }
  .tableDesign {
    thead {
      background-color: #f7f7f7;
      border-top: 1px solid #eaebec;
    }
    th {
      color: #707070 !important;
    }
    td {
      color: #707070;
      font-weight: 500;
    }
    .statusChip {
      color: #707070 !important;
      border: 0.5px solid #d9d9d9 !important;
      display: inline-flex;
      padding: 0px 5px 0px 5px !important;
      border-radius: 5px !important;
      > div {
        width: 8px;
      }
    }
    .personaChip {
      border-radius: 5px;
      margin-right: 16px;
    }
    .luxuryOrange {
      background-color: #ffedd5;
      color: #fea42e;
    }
    .needBlue {
      background-color: #cceffc;
      color: #02afef;
    }
    .exclusiveRed {
      background-color: #fbe1e1;
      color: #e96969;
    }
    .discountPurple {
      background-color: #e5ccf1;
      color: #7d00b7;
    }
    .trendYellow {
      background-color: #f7f5cf;
      color: #d7cf0f;
    }
    .researchGreen {
      background-color: #e5f4eb;
      color: #7dc699;
    }
  }
}
