.formProfile {

  .ant-form-item {
    .ant-form-item-label {
      color: #002857;
      // font-family: Gotham;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-bottom: 18px;

      .ant-form-item-required {
        &::before {
          display: none !important;
        }
        &::after {
          display: inline-block;
          margin-inline-end: 4px;
          line-height: 1;
          content: "*";
          visibility: unset;
          color: #de2b2b;
          font-family: Gotham;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }

    .ant-input {
      border-radius: 10px;
      border: 1px solid #c8c8ce;
      background: #fff;
      height: 40px;
      color: #707070;
      // font-family: Gotham;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .ant-select {
      height: 40px;

      .ant-select-selector {
        border-radius: 10px;
        border: 1px solid #c8c8ce;
        background: #fff;

        color: #707070;
        // font-family: Gotham;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .ant-select-selection-item {
        color: #707070;
        // font-family: Gotham;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }

  .actions {
    display: flex;
    gap: 36px;
    margin-top: 20px;

    .btnCancel {
      border-radius: 20px;
      background: #999;
      border: none !important;
      height: 40px;
      width: 150px;
      color: #fff;
      // font-family: Gotham;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      &:hover {
        opacity: 0.7;
        background: #999;
        color: #fff;
      }
    }

    .btnSave {
      border-radius: 20px;
      background: #002857;
      border: none !important;
      height: 40px;
      width: 150px;
      color: #fff;
      // font-family: Gotham;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      &:hover {
        opacity: 0.7;
        background: #002857;
        color: #fff;
      }
    }
  }
}