.loginForm {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top:70px;
  .formHead {
    color: #002857;
    font-weight: 500;
    font-size: 18px;
    text-align: flex-start;
  }
  .forgotPassword {
    color: #0050bc;
    font-weight: 500;
    cursor: pointer;
  }
  .formCondition {
    color: #002857;
    font-weight: 500;
  }
  .loginButton {
    margin-top: 60px;
    width: 450px;
    font-size: 18px;
    border-radius: 10px;
    padding: 10px;
  }
  .signUp {
    color: #002857;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    margin-top: 15px;
    .link {
      color: #0050bc;
      cursor: pointer;
    }
  }
  .orLine {
    color: #999999;
    font-weight: 500;
    margin-top: 20px;
    text-align: center;
  }
  .ssoButtons {
    margin-top: 35px;
    gap: 35px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    button {
      padding: 8px;
      border-radius: 10px;
      img {
        height: 45px;
      }
    }
  }
  .MuiOutlinedInput-root {
    height: 45px;
    color: #6f7174;
    font-weight: 500;
    border-radius: 10px;
  }
  input {
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 100px white inset;
    }
  }
}

@media screen and (max-width: 1439px) and (min-width: 1025px) {
  .loginForm {
    .loginButton {
      width: 350px;
    }
    .orLine {
      margin-top: 25px;
    }
    .ssoButtons {
      margin-top: 35px;
      gap: 25px;
    }
  }
}

@media screen and (max-width: 1199px) and (min-width: 1025px) {
  .loginForm {
    margin-top:40px;
    .loginButton {
      margin-top: 30px;
      width: 300px;
    }
    .forgotPassword {
      font-size: 14px;
    }
    .orLine {
      margin-top: 20px;
    }
    .ssoButtons {
      margin-top: 25px;
      gap: 20px;
      button {
        img {
          height: 40px;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .loginForm {
    margin-top:60px;
    .loginButton {
      margin-top: 30px;
    }
    .ssoButtons {
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }
}

@media screen and (max-width: 529px) {
  .loginForm {
    .loginButton {
      width: 370px;
    }
  }
}
@media screen and (max-width: 400px) {
  .loginForm {
    .loginButton {
      width: 300px;
    }
  }
}
