.userDetail {
  display: flex;
  align-items: center;
  gap: 28px;
  margin-bottom: 60px;

  .viewAvatar {
    position: relative;

    .defaulltPhoto {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      border: 1px solid #999999;
    }

    .btnEdit {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-color: #999;
      padding: 0;
      position: absolute;
      z-index: 1;
      right: -5px;
      bottom: 2px;
    }
  }

  h3.name {
    color: #002857;
    margin: 0;
    // font-family: Gotham;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }

  h3.modalName {
    color: #6f7174;
  }
}
