.settingProfile {
  margin-top: 30px;
}

.modalAvatar {
  .ant-modal {
    width: 450px !important;
  }
  .ant-modal-close-icon {
    color: #002857;
  }
  .ant-modal-content {
    .ant-modal-body {
      padding: 70px 0;
    }
  }
  .content {
    margin: 0 auto;
    width: 150px;

    .ant-avatar {
      margin-bottom: 60px;
    }

    .btnTakePhoto {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #002857;
      // font-family: Gotham;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      border-radius: 20px;
      border: 1px solid #002857;
      height: 40px;
      gap: 8px;
      margin-bottom: 27px;

      &:hover {
        color: #002857;
        border: 1px solid #002857;
        opacity: 0.7;
      }
    }

    .btnUpload {
      border-radius: 20px;
      background: #002857;
      border: none !important;
      height: 40px;
      color: #fff;
      // font-family: Gotham;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      // margin-top: 27px;

      &:hover {
        background: #002857;
        border: none !important;
        opacity: 0.7;
      }
    }

    .ant-upload {
      width: 100%;
    }
  }
}
