.customerDataBox {
  // display: flex;
  // justify-content: space-between;
  box-sizing: border-box;
  padding: 14px 18px;
  width: 100%;
  border-radius: 10px;
  background: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  position: relative;
  p {
    margin: 0;
  }
  &-avatar {
    display: block;
    width: 72px;
    position: relative;
    &-userIcon {
      position: absolute;
      top: 0;
      left: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 34px;
      height: 34px;
      background-color: #002857;
      color: #fff;
      font-size: 35px;
      border-radius: 50%;
      margin: 0;
    }
  }

  &-inform {
    // flex: 1;
    // display: grid;
    // grid-template-columns: repeat(2, 1fr);
    // gap: 24px 8px;
    &-item {
      display: flex;
      flex-direction: column;
      gap: 6px;
      margin-bottom: 28px;

      &:last-child {
        margin-bottom: 0;
      }

      &-caption {
        text-transform: uppercase;
        color: #002857;
        font-size: 15px;
        font-weight: 700;
        font-family: "Lato-Bold";
      }
      &-value {
        color: #707070;
        font-size: 14px;
      }
    }

    .user {
      text-align: center;
    }
  }

  &-expand {
    position: absolute;
    top: 8px;
    right: 10px;
    color: #999999;
    font-size: 18px;
    margin: 0;
    cursor: pointer;
  }

  &.mobile {
    padding: 16px 20px;

    .customerDataBox-inform {
      &-item {
        gap: 10px;
        &-caption {
          font-size: 12px;
        }
        &-value {
          font-size: 12px;
        }
      }
    }
  }
}

.timeline {
  color: #6f7174;
  font-family: Gotham;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  margin: 0 0 20px;
  text-transform: uppercase;
}

.customerMobile {
  padding: 21px 16px;
  border-radius: 10px;
  // border: 0.03px solid #000;
  background: #fff;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
  margin-bottom: 25px;

  .persona {
    font-size: 12px;
    padding: 5px 11px;
    font-weight: 500;
  }
  .ant-card-body {
    padding: 0;
  }
  .customerHeader {
    display: flex;
    align-items: center;
    padding: 0 6px;
    margin-bottom: 13px;

    .headerAction {
      margin-left: auto;

      .btnView {
        border: none;
        margin-bottom: 40px;
        color: #0063bc;
        margin-right: 24px;
      }
    }

    .avatar {
      width: 70px;
      height: 70px;

      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
    .info {
      margin-left: 26px;
      width: calc(100% - 96px - 50px);
      position: relative;

      h3 {
        color: #002857;
        font-family: Gotham;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        margin-bottom: 4px;
        margin-top: 0;
        width: 100%;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;

        span {
          width: 13px;
          height: 13px;
          border-radius: 50%;
          margin-right: 6px;
          display: inline-block;
          margin-left: -17px;
          position: absolute;
          left: 0px;
          top: 2px;
          z-index: 2;
        }
        span.active {
          background: #7dc699;
        }
        span.offline {
          background: #999;
        }
        span.away {
          background: #EEE637;
        }
      }
    }
    .statusTime {
      height: 70px;
      margin-left: auto;
      text-align: right;
      .status {
        color: #002857;
        font-family: Gotham;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0 0 8px;

        span {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #7dc699;
          margin-left: 5px;
          display: inline-block;
        }
      }
      .time {
        margin: 0;
        color: #02afef;
        font-family: Gotham;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
  .contentDetail {
    padding: 15px 8px;
    border-radius: 5px;
    border: 0.5px solid #d9d9d9;
    margin-bottom: 27px;
    min-height: 76px;
    box-sizing: border-box;

    p {
      margin: 0;
      color: #002857;
      font-family: Gotham;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .time {
      margin: 0;
      color: #02afef;
      font-family: Gotham;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 20px 0 0;
    }
  }
  .actions {
    display: flex;
    align-items: center;
    padding: 0 22px 0 32px;
    box-sizing: border-box;

    .ant-btn-primary {
      height: 40px;
      border-radius: 30px;
      width: calc(100% - 58px);
      background: #0050bc;
      color: #fff;
      font-family: Gotham;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
    }
    .ant-btn-default {
      width: 40px;
      height: 40px;
      color: #0050bc;
      border-color: #0050bc;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-left: 18px;
    }
  }
}


@media (max-width: 768px) {
  .customerMobile {
    padding: 25px 14px;
    .actions {
      padding: 0 8px;
    }
    .customerHeader {
      .headerAction {
        height: 70px;
        box-sizing: border-box;
        padding-top: 7px;
        .btnView {
          margin-right: 10px;
          margin-bottom: 0;
          svg {
            width: 20px !important;
            height: 20px !important;
          }
        }
      }
    }
  }
  .timeline {
    margin-bottom: 14px;
    font-size: 14px;
  }
}

@media (max-width: 375px) {
  .customerMobile {
    .customerHeader {
      .avatar {
        width: 50px;
        height: 50px;
      }
      .headerAction {
        height: 50px;
        padding-top: 0;

        .btnView {
          margin-right: 5px;
        }
      }

      .contentDetail {
        margin-bottom: 20px;
      }

      .actions {
        padding: 0;
      }
    }
  }
}