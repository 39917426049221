.settingDetail {
  .actions {
    display: flex;
    align-items: center;
    gap: 56px;
    margin-top: 84px;

    .btnUpdate {
      background: #002857;
      color: #fff;
      font-weight: 700;
      font-size: 16px;
      border-radius: 30px;
      height: 42px;
      min-width: 150px;

      &:hover {
        background: #002957d8;
        color: #fff !important;
      }
    }

    .btnDelete {
      background: #de2b2b;
      font-weight: 700;
      font-size: 16px;
      color: #fff;
      border-radius: 30px;
      height: 42px;
      min-width: 202px;

      &:hover {
        background: #de2b2bcb;
        color: #fff !important;
      }

      &:disabled {
        background: #d9d9d9;
        color: #fff !important;

        &:hover {
          background: #d9d9d9cf;
          color: #fff !important;
        }
      }
    }

    .btnCancel {
      border: 1px solid #002857;
      border-radius: 30px;
      font-weight: 700;
      font-size: 16px;
      color: #002857;
      height: 42px;
      min-width: 150px;

      &:hover {
        color: #002857;
        border: 1px solid #002857;
        opacity: 0.7;
      }
    }
  }

  .detailItem {
    p {
      font-weight: 500;
      font-size: 14px;
      color: #6f7174;
      margin-bottom: 24px;
    }
    h4 {
      font-size: 14px;
      font-weight: 500;
      color: #002857;
    }
  }
}
