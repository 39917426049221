.verification {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 60px;
  .formTitle {
    color: #002857;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    margin-bottom: 70px;
  }
  .formHead {
    color: #002857;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
  }
  .otpBox {
    margin-top: 25px;
    .MuiFormControl-root {
      width: 50px !important;
      border: 2px solid #002857 !important;
      position: static;
    }
    .MuiFormControl-root > div {
      position: static;
    }
    input {
      font-size: 32px;
      }
    .MuiOtpInput-Box {
      justify-content: center;
    }
    .MuiInputBase-root.MuiInput-root::before {
        position: fixed !important;
      }
  }
  .verificationButton {
    width: 450px;
    font-size: 18px;
    border-radius: 10px;
    padding: 10px;
    background-color: #0050bc;
    margin-top: 50px;
  }
  .subText {
    color: #999999;
    font-weight: 500;
    margin-top: 25px;
    text-align: center;
    .link {
      color: #0050bc;
      cursor: pointer;
    }
  }
  .MuiOutlinedInput-root {
    height: 45px;
    color: #6f7174;
    font-weight: 500;
    border-radius: 10px;
  }
  input {
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 100px white inset;
    }
  }
}

@media screen and (min-width: 1536px) {
  .verification {
    .verificationButton {
      width: 400px;
    }
  }
}

@media screen and (max-width: 1439px) and (min-width: 1025px) {
  .verification {
    .verificationButton {
      width: 350px;
    }
  }
}

@media screen and (max-width: 1199px) and (min-width: 1025px) {
  .verification {
    margin-top: 40px;
    .formTitle {
      margin-bottom: 30px;
    }
    .verificationButton {
      margin-top: 45px;
      width: 300px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .verification {
    margin-top: 50px;
    .formTitle {
      margin-bottom: 40px;
    }
    .verificationButton {
      margin-top: 30px;
    }
  }
}

@media screen and (max-width: 529px) {
  .verification {
    .verificationButton {
      width: 370px;
    }
  }
}
@media screen and (max-width: 400px) {
  .verification {
    .verificationButton {
      width: 300px;
    }
  }
}
